<template>
  <div class="container">
    <div class="image" :style="style">
      <!-- <img :src="img" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['img'],
  computed: {
    style() {
      return `background-image: url(${this.img});`;
    }
  },
  mounted() {
    // console.log('img', this.img);
  }
};
</script>

<style lang="scss" scoped>
.container {
  .image {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-color: transparent;
  }

  // img {
  //   height: unit(400);
  //   width: auto;
  // }
}
</style>
