import { render, staticRenderFns } from "./StorageImage.vue?vue&type=template&id=31fad72c&scoped=true&"
import script from "./StorageImage.vue?vue&type=script&lang=js&"
export * from "./StorageImage.vue?vue&type=script&lang=js&"
import style0 from "./StorageImage.vue?vue&type=style&index=0&id=31fad72c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fad72c",
  null
  
)

export default component.exports