<template>
  <div>
    <div class="message">체크인은 예약시간 10분 전부터 가능합니다.</div>
    <button @click="$emit('close-modal')">확인</button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  font-size: unit(20);
  margin-bottom: unit(20);
}
button {
  font-size: unit(14);
  width: 60%;
  background-color: #48d9eb;
  color: #ffffff;
  border-radius: unit(24);
  text-align: center;
  margin: auto;
}
</style>
