<template>
  <div>
    <div class="message">체크인 후 10분 이후 부터 체크아웃 가능합니다</div>
    <button @click="$emit('close-modal')">확인</button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.message {
  flex-grow: 1;
  text-align: center;
  font-size: unit(20);
  margin-bottom: unit(58);
  font-weight: 600;
}
button {
  font-size: unit(14);
  width: 60%;
  height: unit(46);
  background-color: #48d9eb;
  color: #ffffff;
  border-radius: unit(24);
  text-align: center;
  margin: auto;
  // position: absolute;
  // bottom: unit(10);
}
</style>
