<template>
  <div id="service-product-ticket-single" class="page">
    <!--Festival O-->
    <div v-if="order?.service?.extras?.festival === 1" class="container">
      <div class="page-header" ref="header">
        <div class="page-header__button-items">
          <button v-button type="button" class="page-header__button" @click="onClickBack">
            <img src="/img/icon/header-close.svg" />
          </button>

          <h1 class="title">{{ $__t('이용내역') }}</h1>

          <button v-button type="button" class="page-header__button" @click="onClickRefresh">
            <img src="/img/icon/header-refresh.svg" />
          </button>
        </div>
        <!--header-->
        <div class="page-header__descript">
          <p class="text" v-if="ticket?.status === 'CANCELLED'">{{ $__t('구매 취소가 완료되었습니다') }}</p>
          <p class="text" v-if="ticket?.status === 'ISSUED'">{{ $__t('보관소에서 티켓 번호를 보여주세요') }}</p>
          <p class="text" v-if="ticket?.status === 'CHECK_IN'">{{ $__t('물품 보관이 완료되었습니다') }}</p>
          <p class="text" v-if="ticket?.status === 'CHECK_OUT'">{{ $__t('이용이 완료되었습니다') }}</p>
        </div>
      </div>

      <div class="page-body">
        <ul class="ticket-message__list">
          <!--구매완료-->
          <li :class="{ on: ticket.status !== 'CANCELLED', activated: ticket.status !== 'CANCELLED' }" class="ticket-message">
            <div class="title">
              <img v-if="ticket.status === 'CANCELLED'" src="@/assets/icons/svg/finished-dot.svg" />
              <img v-else src="@/assets/icons/svg/ing-dot.svg" />
              <span v-if="ticket?.status === 'ISSUED' || ticket?.status === 'CANCELLED'">{{ $__t('구매완료') }}</span>
              <span v-if="ticket?.status === 'CHECK_IN'">{{ $__t('Storage completed') }}</span>
              <span v-if="ticket?.status === 'CHECK_OUT'">{{ $__t('이용완료') }}</span>
            </div>

            <div class="content">
              <div class="notice">
                <div class="ticket_num">{{ $__t('티켓 번호') }} : {{ ticket?.ticket_code }}</div>
                <div class="title">{{ $__t(`${ticket?.service?.product_name}`) }}</div>
                <div class="date_quantity">
                  <!-- {{ `${$moment(ticket?.order?.reserve_date_start).format('YYYY.MM.DD')} | 보관수량 : ${ticket?.order?.reserve_quantity}개` }} -->
                  {{ $moment(ticket?.order?.reserve_date_start).format('YYYY.MM.DD') }} | {{ $__t('보관수량') }} : {{ ticket?.ticket_quantity
                  }}{{ $__t('개') }}
                </div>
              </div>
              <div class="buttons">
                <!--구매취소 : 취소된 상태거나 당일일 경우 버튼 클릭 x-->
                <!--비활성화의 경우 : 당일이거나, 티켓이 체크인상태, 체크아웃상태. -->
                <button
                  :class="{
                    inactivated: ticket?.status === 'CHECK_IN' || ticket?.status === 'CHECK_OUT' || ticket?.status === 'CANCELLED'
                  }"
                  type="button"
                  v-button
                  @click="onClickCancelFestivalService"
                >
                  <span>{{ $__t('구매취소') }}</span>
                </button>

                <button type="button" v-button @click="onClickCustomerService" :disabled="ticket.status === 'CANCELLED'">
                  <span>{{ $__t('고객센터') }}</span>
                </button>

                  <button type="button" v-button @click="openRef('storage-img-modal')" :disabled="ticket.status === 'CANCELLED'" :class="{ invisible: !hasStorageLocationImage }">
                  <span>{{ $__t('보관소 위치') }}</span>
                </button>
              </div>
            </div>
            <!--당일이 아니거나, 업로드된사진이 없을때 활성화-->
            <button
              class="imageUpload"
              @click="festivalCheckIn"
              :class="{ inactivated: !canStoreLuggage || ticket?.resources?.luggage_photo?.resource_url }"
            >
              {{ $__t('물품 맡기기') }}
            </button>
            <div class="time">{{ dayAndTime(ticket.datetime_issued) }}</div>
          </li>
          <li
            v-if="ticket?.resources?.luggage_photo?.resource_url"
            class="ticket-message__pickup"
            :class="{ on: ticket.status !== 'CANCELLED', activated: ticket.status !== 'CANCELLED' }"
          >
            <div class="luggageImage">
              <img
                v-if="ticket?.resources?.luggage_photo?.resource_url"
                :src="ticket?.resources?.luggage_photo?.resource_url"
                alt=""
                @click="onClickLuggageImage"
              />
            </div>
            <div class="time">{{ dayAndTime(ticket.datetime_check_in) }}</div>
            <button
              class="pickup"
              v-if="ticket?.resources?.luggage_photo?.resource_url"
              @click="onClickCheckOut"
              :class="{ activated: ticket.status === 'CHECK_IN' }"
              :disabled="ticket.status === 'CHECK_OUT'"
            >
              {{ $__t('물품 찾기') }}
            </button>
            <div v-if="ticket?.status === 'CHECK_OUT'" class="time">{{ dayAndTime(ticket.datetime_check_out) }}</div>
          </li>
          <!--구매취소-->
          <li
            :class="{ on: ticket.status === 'CANCELLED', activated: ticket.status === 'CANCELLED' }"
            v-if="ticket.status === 'CANCELLED'"
            class="ticket-message"
          >
            <div class="title">
              <img v-if="ticket.status === 'CANCELLED'" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('구매취소') }}</span>
            </div>

            <div class="content">
              <div class="title">{{ $__t('구매취소 완료') }}</div>
              <div class="notice">
                <div class="ticket_num">{{ $__t('티켓 번호') }} : {{ ticket?.ticket_code }}</div>

                <div class="title">{{ $__t(`${ticket?.service?.product_name}`) }}</div>
                <div class="date_quantity">
                  <!-- {{ `${$moment(ticket?.order?.reserve_date_start).format('YYYY.MM.DD')} | 보관수량 : ${ticket?.order?.reserve_quantity}개` }}
                   -->
                  {{ $moment(ticket?.order?.reserve_date_start).format('YYYY.MM.DD') }} | {{ $__t('보관수량') }} : {{ ticket?.ticket_quantity
                  }}{{ $__t('개') }}
                </div>
              </div>
              <div class="buttons">
                <button type="button" v-button @click="onClickCustomerService">
                  <span>{{ $__t('고객센터') }}</span>
                </button>
              </div>
            </div>
            <div class="time">{{ dayAndTime(ticket.datetime_cancelled) }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!--Festival X-->
    <div v-else class="container">
      <div class="page-header" ref="header">
        <div class="page-header__button-items">
          <button v-button type="button" class="page-header__button" @click="onClickBack">
            <img src="/img/icon/header-close.svg" />
          </button>

          <h1 class="title">{{ $__t('이용내역') }}</h1>

          <button v-button type="button" class="page-header__button" @click="onClickRefresh">
            <img src="/img/icon/header-refresh.svg" />
          </button>
        </div>
        <!--이용예정/이용중/카운트다운-->
        <!-- 예약시간이 됐을때 && 체크아웃을 안한 상태 -->
        <div class="page-header__descript">
          <p class="text" v-if="ticket?.status === 'CANCELLED'">예약취소</p>
          <p class="text" v-if="ticket?.status === 'ISSUED' && timerStart === false">
            {{ `${year(ticket?.ticket_date_start)}.${dayAndTime(ticket?.ticket_date_start)} 이용예정` }}
          </p>

          <p class="text" v-if="ticket?.status !== 'CHECK_OUT' && timerStart === true">
            <usage-countdown :date="ticket.ticket_date_end" />
          </p>

          <p class="text" v-if="ticket?.status === 'CHECK_OUT'">
            {{ `${year(ticket?.datetime_check_out)}.${dayAndTime(ticket?.datetime_check_out)} 이용완료` }}
          </p>
        </div>
      </div>

      <div class="page-body">
        <ul class="ticket-message__list">
          <!--예약완료-->
          <li :class="{ on: ticket.status === 'ISSUED' && !canCheckIn, activated: ticket.status === 'ISSUED' }" class="ticket-message">
            <div class="title">
              <img v-if="ticket.status === 'ISSUED' && !canCheckIn" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('예약완료') }}</span>
            </div>

            <div class="content">
              <div class="notice">
                {{ ticket?.product?.product_name }}
              </div>

              <!-- <div class="detail">{{ $__t("구매내역 상세보기") }} &gt;</div> -->

              <div class="buttons">
                <button type="button" v-button @click="onClickCancelService" :disabled="ticket.status === 'CANCELLED'">
                  <span>{{ $__t('예약취소') }}</span>
                </button>

                <button type="button" v-button @click="openRef('map-connect-slider')" :disabled="ticket.status === 'CANCELLED'">
                  <span>{{ $__t('상점찾기') }}</span>
                </button>

                <button type="button" v-button @click="onClickCustomerService" :disabled="ticket.status === 'CANCELLED'">
                  <span>{{ $__t('고객센터') }}</span>
                </button>
              </div>
            </div>
            <div class="time">{{ dayAndTime(ticket.datetime_issued) }}</div>
          </li>
          <!--예약취소 : here-->
          <li
            :class="{ on: ticket.status === 'CANCELLED', activated: ticket.status === 'CANCELLED' }"
            v-if="ticket.status === 'CANCELLED'"
            class="ticket-message"
          >
            <div class="title">
              <img v-if="ticket.status === 'CANCELLED'" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('예약취소') }}</span>
            </div>

            <div class="content">
              <div class="title">{{ $__t('예약취소 완료') }}</div>
              <div class="notice">
                {{ ticket?.product?.product_name }}
              </div>
              <div class="buttons">
                <button type="button" v-button @click="onClickCustomerService">
                  <span>{{ $__t('고객센터') }}</span>
                </button>
              </div>
            </div>
            <div class="time">{{ dayAndTime(ticket.datetime_cancelled) }}</div>
          </li>
          <!--here-->
          <!--체크인-->
          <li
            :class="{ on: ticket.status === 'ISSUED' && canCheckIn, activated: ticket.status === 'CHECK_IN' }"
            v-if="ticket.status !== 'CANCELLED'"
            class="ticket-message"
          >
            <div class="title">
              <img v-if="ticket.status === 'ISSUED' && canCheckIn" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('체크인') }}</span>
              <span class="usetime" v-if="ticket.status === 'ISSUED' && !canCheckIn">{{
                `${year(ticket.ticket_date_start)}.${dayAndTime(ticket.ticket_date_start)} 이용예정`
              }}</span>
            </div>

            <!--말풍선-->
            <div class="content" v-if="ticket.status === 'ISSUED' && canCheckIn">
              <div class="notice">
                <div>{{ $__t('예약하신 상품이 오늘 사용 예정입니다.') }}</div>
                <div>
                  <span class="strong">{{ dayAndTime(ticket.ticket_date_start) }}</span>
                  까지 체크인 부탁드립니다.
                </div>
              </div>
              <div class="buttons">
                <button type="button" v-button @click="onClickCustomerService">
                  <span>{{ $__t('고객센터') }}</span>
                </button>
              </div>
            </div>

            <div class="time" v-if="ticket.status === 'ISSUED' && canCheckIn">
              <div class="time">{{ dayAndTime(ticket.ticket_date_start) }}</div>
            </div>
          </li>

          <!--이용중-->
          <li :class="{ on: ticket.status === 'CHECK_IN' }" v-if="ticket.status !== 'CANCELLED'" class="ticket-message">
            <div class="title">
              <img v-if="ticket.status === 'CHECK_IN'" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('이용중') }}</span>
            </div>
            <div class="content" v-if="ticket.status === 'CHECK_IN' || ticket.status === 'CHECK_OUT'">
              <div class="notice">
                <!-- <p>
                  {{
                    $__t('{time} 체크인 완료', {
                      time: $moment(ticket.datetime_check_in).format('MM.DD HH:mm')
                    })
                  }}
                </p> -->
                <p>
                  {{
                    $__t('{time} 체크인 완료', {
                      time: dayAndTime(ticket.datetime_check_in)
                    })
                  }}
                </p>
                <!--라커 이용시-->
                <p v-if="ticket.store_type === 'LOCKER'">라커 이용시 <span class="focus">'라커 열기'</span>를 누르시면 라커 문이 열립니다.</p>
                <!--물품보관 이용시-->
                <p v-if="ticket.store_type === 'LUGGAGE'">{{ ticket?.service?.product_name }}</p>
                <p v-if="ticket.store_type === 'LUGGAGE'">
                  {{
                    `${$moment(new Date(ticket.product_data.reserve_start_date_time)).format('YYYY.MM.DD')} (${getDate(
                      ticket?.product_data?.reserve_start_date_time
                    )})`
                  }}
                  <span class="divide">|</span>
                  {{ `${$moment(new Date(ticket.product_data.reserve_start_date_time)).format('HH:mm')}` }}
                </p>
              </div>
              <div class="buttons">
                <button v-if="ticket.store_type === 'LOCKER'" type="button" v-button @click="onClickOpenLocker">라커열기</button>
                <button
                  v-if="ticket.store_type === 'LUGGAGE'"
                  type="button"
                  v-button
                  @click="onScanned"
                  :class="{ inactivated: ticket?.resources?.luggage_photo?.resource_url }"
                  :disabled="ticket?.resources?.luggage_photo?.resource_url"
                >
                  보관 물품 촬영
                </button>
              </div>
            </div>
            <div class="time" v-if="ticket.status === 'CHECK_IN'">
              {{ dayAndTime(ticket.datetime_check_in) }}
            </div>
            <!-- <img :src="luggageImage" alt="" /> -->
            <img
              v-if="ticket?.resources?.luggage_photo?.resource_url"
              :src="ticket?.resources?.luggage_photo?.resource_url"
              alt=""
              class="luggageImage"
            />
          </li>

          <!--체크아웃-->
          <li
            :class="{ on: ticket.status === 'CHECK_OUT', activated: ticket.status === 'CHECK_OUT' }"
            class="ticket-message"
            v-if="ticket.status !== 'CANCELLED'"
          >
            <div class="title">
              <img v-if="ticket.status === 'CHECK_OUT'" src="@/assets/icons/svg/ing-dot.svg" />
              <img v-else src="@/assets/icons/svg/finished-dot.svg" />
              <span>{{ $__t('체크아웃') }}</span>

              <span class="usetime">
                {{
                  ticket.status === 'CHECK_OUT'
                    ? `${year(ticket.datetime_check_out)}.${dayAndTime(ticket.datetime_check_out)} 체크아웃 완료`
                    : `${year(ticket.ticket_date_end)}.${dayAndTime(ticket.ticket_date_end)} 체크아웃 예정`
                }}</span
              >
            </div>

            <div class="content" v-if="ticket.status === 'CHECK_OUT'">
              <div class="notice">
                <p>
                  <!-- {{
                    $__t('{time} 체크아웃이 완료되었습니다.', {
                      time: $moment(ticket.datetime_check_out).format('MM.DD hh:mm')
                    })
                  }} -->
                  {{
                    $__t('{time} 체크아웃이 완료되었습니다.', {
                      time: dayAndTime(ticket.datetime_check_out)
                    })
                  }}
                </p>
              </div>
              <div class="buttons">
                <button type="button" v-button @click="openRef('map-connect-slider')">
                  <span>{{ $__t('상점찾기') }}</span>
                </button>
                <button type="button" v-button @click="onClickCustomerService">
                  <span>{{ $__t('고객센터') }}</span>
                </button>
              </div>
            </div>

            <div class="time" v-if="ticket.status === 'CHECK_OUT'">
              {{ dayAndTime(ticket.datetime_check_out) }}
            </div>
          </li>
        </ul>
      </div>

      <div class="page-footer" v-if="['ISSUED', 'CHECK_IN'].includes(ticket.status)">
        <div id="check-out-alert-balloon" ref="check-out-alert-balloon" class="page-footer__balloon alert-balloon hidden">
          <div class="content">
            <img src="/img/icon/megaphone.svg" class="content__icon" />
            <span class="content__text">{{ $__t('물품 보관 완료 10분 뒤부터 사용가능합니다.') }}</span>
          </div>
        </div>

        <div class="button-area">
          <template v-if="ticket.status === 'ISSUED'">
            <button type="button" class="ui-submit-button activated" v-ripple="'rgba( 206, 212, 218, 0.35 )'" @click="onClickCheckIn">
              <img src="/img/icon/icon_QR.svg" />
              <span class="ui-submit-button__text"> {{ $__t('체크인') }} </span>
            </button>
          </template>

          <template v-if="ticket.status === 'CHECK_IN'">
            <button type="button" class="ui-submit-button activated" v-ripple="'rgba( 206, 212, 218, 0.35 )'" @click="onClickCheckOut">
              <img src="/img/icon/icon_QR.svg" />
              <span class="ui-submit-button__text">
                {{ $__t('체크아웃') }}
              </span>
            </button>
          </template>
        </div>
      </div>
    </div>

    <ui-slider id="map-connect-slider" ref="map-connect-slider" :bgClick="true" :background="true">
      <map-connect :store="store" @close="closeRef('map-connect-slider')" />
    </ui-slider>

    <guide-modal id="condition-modal" ref="condition-modal" :transparent="true">
      <template v-slot:body>
        <ticket-edit id="ticket-edit" @close-modal="closeRef('condition-modal')" :order="order" />
      </template>
    </guide-modal>

    <modal id="checkin-alert-modal" ref="checkin-alert-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <checkin-alert id="checkin-alert" @close-modal="closeRef('checkin-alert-modal')" />
      </template>
    </modal>

    <modal id="cancel-modal" ref="cancel-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <book-cancel id="book-cancel" @close-modal="closeRef('cancel-modal')" :ticket_code="ticket?.ticket_code" @submit="festivalCancel" />
      </template>
    </modal>
    <!--@TODO: 구매당일 또는 이후에는 취소안된다는 모달-->
    <modal id="unable-cancel-modal" ref="unable-cancel-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <unable-cancel id="unable-cancel" @close-modal="closeRef('unable-cancel-modal')" />
      </template>
    </modal>
    <modal id="storage-img-modal" ref="storage-img-modal" :transparent="true" :hide-footer="true" @close-modal="closeRef('storage-img-modal')">
      <template v-slot:body>
        <storage-image id="storage-img-modal" @close-modal="closeRef('storage-img-modal')" :img="order?.service?.resources?.storage_location_image" />
      </template>
    </modal>

    <modal id="luggage-img-modal" ref="luggage-img-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <stock-image
          id="luggage-img-modal"
          @close-modal="closeRef('luggage-img-modal')"
          :stock_img="ticket?.resources?.luggage_photo?.resource_url"
        />
      </template>
    </modal>

    <ui-slider id="invite-slider" ref="invite-slider" :bgClick="true">
      <ticket-invite @close-modal="closeRef('invite-slider')" />
    </ui-slider>

    <ui-slider id="check-in-slider" ref="check-in-slider" :bgClick="true">
      <ticket-check-in @fetch-order="onCheckIn" :ticket="ticket" @close="closeRef('check-in-slider')" id="festival" />
    </ui-slider>

    <ui-slider id="check-out-slider" ref="check-out-slider" :bgClick="true">
      <ticket-check-out @close-slider="closeRef('check-out-slider')" @fetch-order="onCheckOut" :ticket="ticket" id="festival"></ticket-check-out>
    </ui-slider>

    <!-- <ui-slider id="check-out-slider" ref="check-out-slider">
      <ticket-check-out
        @close-slider="closeRef('check-out-slider')"
        @fetch-ticket="fetchTicket"
        @fetch-order="fetchOrder"
        :duration="usageDuration"
        :ticket="ticket"
        :order="order"
      ></ticket-check-out>
    </ui-slider> -->

    <guide-modal id="find-modal" ref="findModal" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <div class="include-modal">
          <div class="include-modal__header">
            <div class="include-modal__header--primary">
              {{ isLocker ? $__t('라커 찾기') : $__t('상점 찾기') }}
            </div>
            <div class="include-modal__header--secondary">
              {{ ticket.status === 'ISSUED' && !isLocker ? $__t('보관하실 상점 위치를 확인해보세요.') : '' }}
            </div>
          </div>
          <div class="include-modal__body">
            <div class="map-button table fixed">
              <ul class="map-button__list table-row">
                <li class="map-button__item table-cell middle">
                  <button v-button type="button" @click="onClickMapAction('google')" class="circle-button">
                    <img src="/img/button/find-google-map.svg" />
                  </button>
                </li>
                <li v-if="$store.state.config.isTouchDevice" class="map-button__item table-cell middle">
                  <button v-button type="button" @click="onClickMapAction('naver')" class="circle-button">
                    <img src="/img/button/find-naver-map.svg" />
                  </button>
                </li>
                <li class="map-button__item table-cell middle text-button">
                  <button
                    type="button"
                    v-button
                    @click="onClickMapAction('copy')"
                    data-clipboard-target="#clipboard-store-address"
                    class="circle-button copy-btn"
                  >
                    <div class="text">{{ $__t('주소복사') }}</div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </guide-modal>
  </div>
</template>

<script>
import MessageLockerIssued from '@/components/includes/TicketSingle/MessageLockerIssued.vue';
import MessageLockerExpired from '@/components/includes/TicketSingle/MessageLockerExpired.vue';
import MessageLockerCheckIn from '@/components/includes/TicketSingle/MessageLockerCheckIn.vue';
import MessageLockerCheckOut from '@/components/includes/TicketSingle/MessageLockerCheckOut.vue';

import MessageFreePass from '@/components/includes/TicketSingle/MessageFreePass.vue';

import MessageIssued from '@/components/includes/TicketSingle/MessageIssued';
import MessageCheckIn from '@/components/includes/TicketSingle/MessageCheckIn';
import MessageCheckOut from '@/components/includes/TicketSingle/MessageCheckOut';
import MessageCancelled from '@/components/includes/TicketSingle/MessageCancelled';
import MessageExpired from '@/components/includes/TicketSingle/MessageExpired';
import MessageInvited from '@/components/includes/TicketSingle/MessageInvited';

import TicketEdit from '@/components/pages/store/StoresSearchCondition.vue';
import TicketInvite from '@/components/includes/TicketSingle/InviteForm.vue';
import TicketCheckIn from '@/components/pages/service-product/tickets/TicketCheckIn.vue';
import TicketCheckOut from '@/components/pages/service-product/tickets/TicketCheckOut.vue';
// import TicketCheckOut from '@/components/includes/TicketSingle/CheckOutForm.vue';

import CheckOutUnavailable from '@/components/includes/TicketSingle/CheckOutUnavailable.vue';
import MapConnect from '@/components/resources/map/MapConnect.vue';

import LockerBoxSelector from '@/components/resources/locker/LockerBoxSelector.vue';

import UiSlider from '@/components/modules/Slider/Body.vue';
import UsageCountdown from '@/components/pages/service-product/utils/UsageCountdown.vue';
import CheckinAlert from '@/components/pages/service-product/utils/CheckinAlert.vue';
import BookCancel from '@/components/pages/service-product/utils/BookCancel.vue';
import UnableCancel from '@/components/pages/service-product/utils/UnableCancel.vue';
import StockImage from '@/components/pages/service-product/utils/StockImage.vue';
import StorageImage from '@/components/pages/service-product/utils/StorageImage.vue';
import _ from 'lodash';
import dayjs from 'dayjs';

export default {
  name: 'ServiceProductTicketSingle',

  components: {
    MessageLockerIssued,
    MessageLockerExpired,
    MessageLockerCheckIn,
    MessageLockerCheckOut,

    MessageFreePass,

    MessageIssued,
    MessageCheckIn,
    MessageCheckOut,
    MessageCancelled,
    MessageExpired,
    MessageInvited,

    TicketEdit,
    TicketInvite,
    TicketCheckIn,
    TicketCheckOut,

    LockerBoxSelector,

    UiSlider,
    MapConnect,
    UsageCountdown,
    CheckinAlert,
    BookCancel,
    UnableCancel,
    StockImage,
    StorageImage,
    dayjs
  },

  props: ['id'],
  data() {
    return {
      loaded: false,

      order_id: 0,
      invitation: [],

      moment_datetime_now: null,
      fetchTimer: null,
      usageTimer: null,

      usageDuration: 0,

      lockerPrice: 0,
      price: 0,
      checkoutTime: '',

      query: {
        method: this.$route.query.method,
        action: this.$route.query.action
      }
    };
  },

  computed: {
    ticket() {
      const ticket = this.$store.getters['tickets/GET_ITEM']({
        key: 'ticket_code',
        value: this.$props.id
      });
      return ticket || {};
    },
    order() {
      return this.$store.getters['orders/GET_ITEM']({
        key: 'order_id',
        value: this.ticket?.receipt_id
      });
    },

    store() {
      return this.$store.getters['stores/GET_ITEM']({
        key: 'id',
        value: this.ticket?.store_id
      });
    },
    canCheckIn() {
      const currentTime = this.$moment();
      const useTime = this.$moment(this.ticket.ticket_date_start);
      // const duration = currentTime.diff(useTime, "hours");
      const duration = useTime.diff(currentTime, 'hours');

      return currentTime.format('MM.DD') === useTime.format('MM.DD') && duration < 1;
    },

    //현재 시간이 당일보다 전날이면 cancel 가능
    canCancelOrder() {
      //@TODO: 테스트용
      // if (this.$store.state.config.mode !== 'production') {
      //   return false;
      // }

      const currentTime = dayjs(dayjs().format('YYYY-MM-DD'));
      const bookedTime = dayjs(dayjs(this.ticket.ticket_date_start).format('YYYY-MM-DD'));

      return currentTime.isBefore(bookedTime) || currentTime.isSame(bookedTime);
    },

    hasStorageLocationImage() {
      return this.order?.service?.resources?.storage_location_image;
    },

    canStoreLuggage() {
      // const currentTime = dayjs(dayjs().format('YYYY-MM-DD'));
      // const bookedTime = dayjs(dayjs(this.ticket.ticket_date_start).format('YYYY-MM-DD'));

      // return currentTime.isSame(bookedTime);
      return true;
    },

    timerStart() {
      const currentTime = this.$moment();
      const useTime = this.$moment(this.ticket.ticket_date_start);
      const duration = useTime.diff(currentTime);
      return duration <= 0;
    },

    headerPrimaryText() {
      let text = '';

      switch (this.ticket?.status) {
        case 'EXPIRED':
          text = this.$__t('{ticket-single}.header-primary.EXPIRED');
          break;
        case 'CANCELLED':
          text = this.$__t('{ticket-single}.header-primary.CANCELLED');
          break;
        case 'ISSUED':
          text = this.$__t('{ticket-single}.header-primary.COMPLETE');

          //temp
          if (this.order?.product_data?.store_type === 'LOCKER' && this.order?.product_data?.order_type === 'IMMEDIATE') {
            text = this.$__t('{ticket-single}.header-primary.LOCKER');
          }
          break;
        case 'CHECK_IN':
          text = this.$__t('{ticket-single}.header-primary.CHECK_IN');
          //temp
          if (this.order?.product_data?.store_type === 'LOCKER' && !this.isLockerCheckInConfirmed) {
            text = this.$__t('{ticket-single}.header-primary.LOCKER_ACTIVATED');
          }
          break;
        case 'CHECK_OUT':
          text = this.$__t('{ticket-single}.header-primary.CHECK_OUT');

          if (this.order?.product_data?.store_type === 'LOCKER') {
            text = this.$__t('{ticket-single}.header-primary.LOCKER_CHECK_OUT');
          }
          break;
      }

      return this.$__t(text);
    },

    headerSecondaryText() {
      let text = '';

      switch (this.ticket?.status) {
        case 'ISSUED':
          text = this.$__t('{ticket-single}.header-secondary.ISSUED');
          if (this.order?.product_data?.store_type === 'LOCKER') {
            text = this.$__t('{ticket-single}.header-secondary.LOCKER_ISSUED_DELAYED').replace(
              '{TICKET_DATE_START}',
              this.$moment(this.ticket.ticket_date_start).format('HH:mm')
            );
          }
          break;
        case 'CANCELLED':
          text = `럭스테이 예약이 <strong>${this.$moment(this.ticket.datetime_cancelled).format('M월 DD일 HH:mm')}</strong>에 취소되었습니다.`;
          break;
        case 'CHECK_IN':
          text = this.$__t('{ticket-single}.header-secondary.CHECK_IN');

          if (this.order?.product_data?.store_type === 'LOCKER') {
            if (this.isLockerCheckInConfirmed) {
              text = this.$__t('{ticket-single}.header-secondary.LOCKER_CHECK_IN_CONFIRMED');
            } else {
              text = this.$__t('{ticket-single}.header-secondary.LOCKER_CHECK_IN');
            }
          }
          break;
        case 'CHECK_OUT':
          text = this.$__t('{ticket-single}.header-secondary.CHECK_OUT');
          break;
      }

      return this.$__t(text);
    },

    headerTicketUsageTimeText() {
      let formula = '00:00:00';

      const _duration = this.usageDuration;

      try {
        if (this.ticket?.datetime_check_in) {
          formula = '';
          let duration_seconds = String(_duration.seconds());
          let duration_minutes = String(_duration.minutes());
          let duration_hours = String(_duration.hours());

          formula = `${duration_seconds.length < 2 ? '0' + duration_seconds : duration_seconds}`;
          formula = `${duration_minutes.length < 2 ? '0' + duration_minutes : duration_minutes}:` + formula;
          formula = `${duration_hours.length < 2 ? '0' + duration_hours : duration_hours}:` + formula;

          if (Math.floor(_duration.asDays()) != 0) {
            formula = `${Math.floor(_duration.asDays())}${this.$__t('d')} ` + formula;
          }
        }
      } catch (e) {
      } finally {
        return formula;
      }
    },

    passDuration() {
      try {
        if (this.ticket?.datetime_check_in && this.order) {
          let timeDiffDuration = null;
          let fromMoment = this.moment_datetime_now || this.$moment();
          let endMoment = this.$moment(this.order.promotion.pass_date_end);

          let diffMoment = endMoment.diff(fromMoment);

          if (diffMoment < 0) {
            diffMoment = 0;
          }

          timeDiffDuration = this.$moment.duration(diffMoment);

          return timeDiffDuration;
        }
      } catch (e) {
        console.error(e);
      }
    },

    headerTicketUsagePriceText() {
      if (this.ticket?.datetime_check_out) {
        return (this.order?.payment_total || 0).toLocaleString();
      }

      if (this.ticket?.datetime_check_in) {
        if (this.ticket?.ticket_type === 'LOCKER' || this.order?.product_data?.store_type === 'LOCKER') {
          return this.lockerPrice.toLocaleString();
        }

        return (this.price?.total || 0).toLocaleString();
      }
    },

    isValidInvitation() {
      const view_type = localStorage.getItem('__LUGSTAY__VIEW__TYPE__');

      if (view_type === 'iez') {
        return false;
      }

      return Array.isArray(this.invitation) && this.invitation.length > 0;
    },

    messageComponents() {
      return this.isLocker ? this.lockerMessageComponents : this.storeMessageComponents;
    },

    lockerMessageComponents() {
      const ALL_MESSAGES = [
        'MessageLockerIssued',
        'MessageLockerExpired',
        'MessageCancelled',
        'MessageLockerCheckIn',
        'MessageInvited',
        'MessageLockerCheckOut'
      ];

      let messageList = [];

      if (this.ticket?.status === 'EXPIRED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageLockerIssued' || message_name === 'MessageLockerExpired');
      } else if (this.ticket?.status === 'ISSUED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageLockerIssued');
      } else if (this.ticket?.status === 'CANCELLED') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <= ALL_MESSAGES.findIndex((_message_name) => _message_name === 'MessageCancelled') && message_name !== 'MessageLockerExpired'
        );
      } else if (this.ticket?.status === 'CHECK_IN') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <= ALL_MESSAGES.findIndex((_message_name) => _message_name === 'MessageInvited') &&
            message_name !== 'MessageLockerExpired' &&
            message_name !== 'MessageCancelled' &&
            (message_name === 'MessageInvited' ? this.isValidInvitation : true)
        );
      } else if (this.ticket?.status === 'CHECK_OUT') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            message_name !== 'MessageLockerExpired' &&
            message_name !== 'MessageCancelled' &&
            (message_name === 'MessageInvited' ? this.isValidInvitation : true)
        );
      }

      if (this.order?.promotion_pass_code && this.order?.promotion.pass_date_start) {
        messageList.unshift('MessageFreePass');
      }

      return messageList;
    },

    storeMessageComponents() {
      const ALL_MESSAGES = ['MessageExpired', 'MessageIssued', 'MessageCancelled', 'MessageCheckIn', 'MessageInvited', 'MessageCheckOut'];

      let messageList = [];

      if (this.ticket?.status === 'EXPIRED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageExpired');
      } else if (this.ticket?.status === 'ISSUED') {
        messageList = ALL_MESSAGES.filter((message_name) => message_name === 'MessageIssued');
      } else if (this.ticket?.status === 'CANCELLED') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <= ALL_MESSAGES.findIndex((_message_name) => _message_name === 'MessageCancelled') && message_name !== 'MessageExpired'
        );
      } else if (this.ticket?.status === 'CHECK_IN') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            index <= ALL_MESSAGES.findIndex((_message_name) => _message_name === 'MessageInvited') &&
            message_name !== 'MessageExpired' &&
            message_name !== 'MessageCancelled' &&
            (message_name === 'MessageInvited' ? this.isValidInvitation : true)
        );
      } else if (this.ticket?.status === 'CHECK_OUT') {
        messageList = ALL_MESSAGES.filter(
          (message_name, index) =>
            message_name !== 'MessageExpired' &&
            message_name !== 'MessageCancelled' &&
            (message_name === 'MessageInvited' ? this.isValidInvitation : true)
        );
      }

      if (this.order?.promotion_pass_code && this.order?.promotion.pass_date_start) {
        messageList.unshift('MessageFreePass');
      }

      return messageList;
    }
  },

  watch: {
    ticket: {
      async handler(ticket, oldTicket) {
        if (ticket?.status !== oldTicket?.status) {
          this.$store.commit('loading/SET_TRUE');
          setTimeout(() => {
            this.scrollToActivatedTicketMessage();
          }, 0);

          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
          }, 1000);
        }

        if (ticket?.status === 'CHECK_IN') {
          // if (!this.usageTimer) {
          //   this.setUsageTimer();
          // }
        }

        if (oldTicket && ticket?.status !== 'CHECK_IN') {
          // clearInterval(this.usageTimer);
        }

        if (ticket?.status === 'CHECK_OUT') {
          // this.setUsageDuration();
        }
      },
      deep: true
    }
  },

  async mounted() {
    // console.log('props', this.$props.id);
    await this.fetchTicket();
    await this.fetchOrder();
    await this.fetchStore();
    await this.scrollToActivatedTicketMessage();
  },

  beforeDestroy() {},

  methods: {
    // Fetch Data
    async fetchTicket() {
      return await this.$store.dispatch('tickets/get', { id: this.$props.id });
    },

    fetchOrder() {
      return this.$store.dispatch('orders/order', {
        id: this.ticket.receipt_id
      });
    },

    fetchStore() {
      return this.$store.dispatch('stores/get', { id: this.ticket.store_id });
    },

    cancelOrder() {
      return this.$store.dispatch('serviceProduct/cancel', {
        id: this.ticket.receipt_id
      });
    },

    openLocker() {
      return this.$store.dispatch('serviceProduct/openLocker', {
        ticket_id: this.ticket.ticket_id
      });
    },

    // Utils
    getDate(date) {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      let dayOfWeek = week[new Date(date).getDay()];
      return dayOfWeek;
    },
    // Actions
    checkEarlyCheckIn() {
      // 현재시간이 체크인 시간보다 10분 더 이전이면 경고모달 뜨게.
      let currentTime = this.$moment();
      let tenminsBefore = this.$moment(this.ticket?.ticket_date_start).subtract(10, 'minutes');
      let timeDuration = this.$moment.duration(currentTime.diff(tenminsBefore)).asMilliseconds();
      if (timeDuration < 0) {
        return true;
      }

      return false;
    },
    async festivalCheckIn() {
      // await this.checkIn(); //체크인 ok.
      // await this.onCheckIn(); //fetchTicket
      //사진촬영 slider 뜨도록

      //@MEMO: 9/15 UMF컨텐츠를 위해서 사진 촬영 시, 이미지 업로드 && 체크인도 같이 되도록 수정
      this.onScanned();
    },

    async festivalCancel() {
      this.$store.commit('loading/SET_TRUE');

      try {
        const params = {
          id: this.ticket.ticket_id
        };

        let res = await this.$store.dispatch('serviceProduct/cancelTicket', params);

        this.$store.commit('alert/ADD_ITEM', {
          message: this.$__t('예약이 취소되었습니다.'),
          status: 'success'
        });
        this.closeRef('cancel-modal');
      } catch (e) {
        console.error(e);

        this.$store.commit('alert/ADD_ITEM', {
          message: '취소가 불가한 티켓입니다. 고객센터로 문의 부탁드립니다.',
          status: 'error'
        });
      } finally {
        this.fetchTicket().then(() => {
          setTimeout(() => {
            this.$store.commit('loading/SET_FALSE');
            // this.$router.back();
          }, 50);
        });
      }
    },

    openQRCodeReader({ store_id, onError, onScanned }) {
      return this.$store.dispatch('qrCodeScanner/open', {
        type: 'onetime-callback',
        callback: async ({ status, code, store_id, error }) => {
          try {
            if (status === 'ERROR') {
              throw error;
            }

            if (status === 'SUCCESS') {
              // if (onScanned) {
              //   const item = await this.$store.dispatch('qrCodeScanner/findTicket', {
              //     store_id: this.ticket.store_id,
              //     ticket_code: this.ticket.ticket_code
              //   });

              //   onScanned(item);
              // }
              //here
              // await this.$store.dispatch('qrCodeScanner/findTicket', {
              //   store_id: this.ticket.store_id,
              //   ticket_code: this.ticket.ticket_code
              // });
              await this.checkIn(); //체크인 ok.
              await this.onCheckIn(); //fetchTicket
              const item = await this.$store.dispatch('qrCodeScanner/findTicket', {
                store_id: this.ticket.store_id,
                ticket_code: this.ticket.ticket_code
              });

              onScanned(item); //사진촬영 slider뜨도록.
            }
          } catch (e) {
            onError(e);
          }
        }
      });
    },
    async checkIn() {
      try {
        await this.$store.dispatch('tickets/luggageCheckIn', {
          code: this.ticket.ticket_code
        });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
        console.error(e);
      }
    },
    openToCheckIn() {
      //아니면 checkin 슬라이더 뜨도록
      this.openModal('check-in-slider');
    },

    // Events
    onClickBack() {
      if (this.$route.query.from) {
        return this.$router.push({ name: this.$route.query.from });
      }

      return this.$router.back();
    },

    async onClickRefresh() {
      this.$store.commit('loading/SET_TRUE');
      await this.fetchTicket();
      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
      }, 500);
    },

    onClickCheckIn: _.debounce(async function () {
      // if (this.checkEarlyCheckIn) {
      //   this.openModal('checkin-alert-modal');
      //   return;
      // }

      //Locker면 사진촬영 슬라이더 안뜨도록
      if (this.ticket.store_type === 'LOCKER') {
        await this.openQRCodeReader({
          store_id: this.ticket.store_id,
          onError: (error) => {
            if (error.message === 'NOT_FOUND_TICKET') {
              this.$store.commit('alert/ADD_ITEM', {
                message: this.$__t('해당 상점에 예약된 티켓을 찾을 수 없습니다.'),
                status: 'error'
              });
              return;
            }
            this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t(error.toString()),
              status: 'error'
            });
          },
          onScanned: (item) => {
            return;
          }
        });
      } else {
        await this.openQRCodeReader({
          store_id: this.ticket.store_id,
          onError: (error) => {
            if (error.message === 'NOT_FOUND_TICKET') {
              this.$store.commit('alert/ADD_ITEM', {
                message: this.$__t('해당 상점에 예약된 티켓을 찾을 수 없습니다.'),
                status: 'error'
              });
              return;
            }
            this.$store.commit('alert/ADD_ITEM', {
              message: this.$__t(error.toString()),
              status: 'error'
            });
          },
          onScanned: (item) => {
            this.openToCheckIn();
          }
        });
      }
    }, 100),

    onScanned() {
      this.openToCheckIn();
    },
    onClickCheckOut() {
      this.openModal('check-out-slider');
    },

    async onCheckIn() {
      await this.fetchTicket();
    },

    async onCheckOut() {
      await this.fetchTicket();
    },

    async onClickOpenLocker() {
      try {
        await this.openLocker();
      } catch (e) {
        console.error(e);
      }
    },

    onClickCancelService() {
      return this.$router.push({
        name: 'ServiceProductTicketCancel',
        parmas: { id: this.ticket.ticket_code }
      });
    },

    onClickCancelFestivalService() {
      //@MEMO:TEST용
      // if (this.$store.state.config.mode !== 'production') {
      //   return this.openModal('cancel-modal');
      // }
      //당일이거나 당일 이후면
      if (!this.canCancelOrder) {
        this.openModal('unable-cancel-modal');
      } else {
        //당일이 아니면
        this.openModal('cancel-modal');
      }
    },

    onClickCustomerService() {
      // _.debounce(function () {
      //   location.href = "tel:+82-218779727";
      // }, 500);
      return this.$router.push({
        name: 'Cs'
        // parmas: { id: this.ticket.ticket_code },
      });
    },

    // -- 정리중

    onClickLuggageImage() {
      this.openModal('luggage-img-modal');
    },

    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      this.$refs[ref].close();
    },

    openModal(ref) {
      this.$refs[ref].open();
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },

    //
    dayAndTime(date) {
      let result = this.$moment(date).format('MM.DD');
      let day = this.$moment(date).day();
      switch (day) {
        case 1:
          result = result + ' (월)';
          break;

        case 2:
          result = result + ' (화)';
          break;

        case 3:
          result = result + ' (수)';
          break;

        case 4:
          result = result + ' (목)';
          break;
        case 5:
          result = result + ' (금)';
          break;
        case 6:
          result = result + ' (토)';
          break;
        case 7:
          result = result + ' (일)';
          break;
      }
      let time = this.$moment(date).format('HH:mm');
      result = result + ' ' + time;
      return result;
    },

    year(date) {
      return this.$moment(date).format('YYYY');
    },

    openNaverMap(url) {
      const encodedUrl = encodeURI(url);

      if (this.wni.isNative) {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          this.wni.execute('wnOpenURL', { url: url });
          setTimeout(() => {
            if (!document.hidden) {
              const _url =
                url.replace('nmap://', 'intent://') +
                '#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end';
              return this.wni.execute('wnOpenURL', { url: _url });
            }
          }, 2000);
        }
      } else {
        if (this.$store.state.config.ios) {
          this.openNaverMapInIphoneBrowser(encodedUrl);
        } else if (this.$store.state.config.android) {
          url =
            url.replace('nmap://', 'intent://') +
            '#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end';
          location.href = url;
        } else {
          location.href = url;
        }
      }
    },

    openNaverMapInIphoneBrowser(url) {
      this.$store.commit('loading/SET_TRUE');

      window.location.href = url;

      setTimeout(() => {
        try {
          if (!document.hidden) {
            throw new Error('1102:NO_EXISTS_DATA');
          }
        } catch (e) {
          let confirmed = confirm(this.$__t('Naver map is not installed, so go to the App Store.'));

          if (confirmed) {
            location.href = 'http://itunes.apple.com/app/id311867728?mt=8';
          }
        } finally {
          this.$store.commit('loading/SET_FALSE');
        }
      }, 1000);
    },

    reload() {
      this.$store.commit('loading/SET_TRUE');

      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
      }, 1000);
    },

    scrollToActivatedTicketMessage() {
      return this.$nextTick(() => {
        const activatedList = this.$el.querySelectorAll('.ticket-message.activated');
        const header = this.$refs.header;

        if (activatedList.length > 0) {
          const elOffset = $(activatedList[activatedList.length - 1]).offset().top;
          const headerHeight = header.offsetHeight;

          let offset = elOffset - headerHeight;

          $('html, body').animate({ scrollTop: offset }, 200);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#find-modal::v-deep.lug-guide-modal {
  & > .lug-guide-modal-container {
    width: 90%;
    max-width: 320px;
  }
}

#service-product-ticket-single {
  @include fill-viewport-min-height;

  .page-header {
    padding: env(safe-area-inset-top) 0 0 0;
    background-color: $color-white;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;

    &__button-items {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: space-between;
      height: unit(56);

      > .title {
        flex: 1;
        text-align: center;
        font-weight: 500;
        font-size: unit(14);
        line-height: 1.45;
        color: $color-black;
      }
    }

    &__button {
      width: unit(56);
      height: unit(56);

      img {
        margin: auto;
        width: unit(24);
        height: unit(24);
        object-fit: contain;
      }
    }

    &__descript {
      display: flex;
      flex-direction: column;
      margin: unit(8) 0 unit(16);

      p.text {
        flex: 1;
        text-align: center;

        margin-top: unit(4);
        font-size: unit(20);
        color: #292a2b;
        font-weight: 700;
        letter-spacing: -0.006em;

        &::v-deep > strong {
          color: $color-black;
          font-weight: bold;
        }
      }
      .buttons {
        margin: unit(8) unit(24);
        display: flex;
        justify-content: space-between;
        gap: unit(8);

        button {
          height: unit(32);
          border: #ced3d6 1px solid;
          border-radius: 24px;
          font-weight: 700;
          font-size: unit(12);
        }
      }
    }
  }

  .page-body {
    padding: unit(30) unit(16) unit(100) unit(16);
    // padding-bottom: 300px;

    .ticket-message__list {
      li {
        padding-bottom: unit(25);

        &.on {
          .title {
            font-weight: bold;
            color: #292a2b;
          }
        }
        &:not(.on) {
          opacity: 0.5;
          pointer-events: none;
        }
        .content {
          margin-top: unit(15);
          background-color: white;
          border-radius: unit(24);
          padding: unit(18);

          .title {
            color: #4d4dff;
            font-size: unit(12);
            font-weight: 700;
          }
          .notice {
            color: #292a2b;
            font-size: unit(14);
            font-weight: 500;

            .ticket_num {
              font-size: unit(18);
              font-weight: 600;
            }

            .title {
              font-size: unit(14);
              color: #292a2b;
              font-weight: 500;
            }

            .divide {
              margin: 0 unit(2);
            }

            .strong {
              color: #ff4646;
            }
            .focus {
              font-weight: bold;
            }
          }
          .detail {
            color: #4d4dff;
            font-size: unit(12);
            font-weight: 400;
          }
        }
      }

      .title {
        color: #ced3d6;
        font-weight: 600;
        font-size: unit(18);
        line-height: unit(26);
        display: flex;

        span {
          margin-left: unit(12);
        }

        .usetime {
          color: #a9afb3;
          font-weight: 600;
          font-size: unit(12);
        }
      }
      .imageUpload {
        background: $color-white;
        border-radius: 24px;
        height: unit(41);
        border: 1px solid #ced3d6;
        margin: unit(10) 0 0 0;
        color: #292a2b;
        font-weight: 700;
        font-size: unit(12);
        &.inactivated {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .time {
        text-align: right;
        color: #a9afb3;
        font-size: unit(12);
      }
      .luggageImage {
        // height: unit(170);
        // width: unit(256);
        height: unit(170);
        width: 100%;
        border: 1px solid #e1e4e6;
        border-radius: 6px;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          // border-radius: 6px;
        }
      }
      .pickup {
        background-color: #ced3d6;
        color: white;
        height: unit(40);
        // width: unit(256);
        width: 100%;
        border-radius: 24px;
        margin-top: unit(15);
        &.activated {
          color: white;
          background-color: $color-primary-9;
        }
      }
      .buttons {
        margin-top: unit(20);
        display: flex;
        justify-content: space-between;
        gap: unit(8);
        .inactivated {
          opacity: 0.4;
          pointer-events: none;
        }

        button {
          height: unit(32);
          border: #ced3d6 1px solid;
          border-radius: 24px;
          font-weight: 700;
          font-size: unit(12);
          &.invisible {
            visibility: hidden;
          }
        }
      }
    }

    .ticket-message__list-item {
      & + .ticket-message__list-item {
        margin-top: unit(30);
      }
    }
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: unit(82);
    background: #ffffff;
    box-shadow: 0px unit(1) unit(5) #a9afb3;
    border-radius: unit(20) unit(20) 0px 0px;

    .button-area {
      width: 100%;
      padding: unit(16);

      i {
        color: $color-white;
        margin-right: unit(4);
        font-size: unit(16);
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: unit(16);
        background-color: #48d9eb;
        border-radius: 24px;
      }
    }

    &__balloon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      width: auto;
      min-width: unit(280);
      justify-content: center;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16)) drop-shadow(4px 8px 28px rgba(0, 0, 0, 0.08));
      transition: all 0.2s;
      opacity: 1;

      .hidden {
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }
}
</style>
