<template>
  <div class="container">
    <div class="image">
      <img :src="stock_img" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['stock_img']
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .message {
    text-align: center;
    font-size: unit(20);
    margin-top: unit(30);
    margin-bottom: unit(58);
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: unit(10);
    // position: absolute;
    // top: unit(2);
    button {
      font-size: unit(14);
      width: 100%;
      height: unit(46);
      border-radius: unit(24);
      text-align: center;
      margin: auto;

      &.yes {
        background-color: #48d9eb;
        color: #ffffff;
      }
      &.no {
        background-color: #ffffff;
        color: #a9afb3;
        border: 1px solid #e1e4e6;
      }
    }
  }
}
</style>
