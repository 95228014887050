<template>
  <div class="container">
    <img src="@/assets/icons/svg/icon_clock.svg" />
    {{ duration }}
  </div>
</template>

<script>
export default {
  props: ["date"],
  data() {
    return {
      duration: "00:00:00",
      timerId: null,
      ria: 0,
    };
  },
  mounted() {
    //@TODO: 현재 남은 시간을 계산 & 시간 형태로 포맷팅하는것..
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    timer() {
      let currentTime = this.$moment();
      let useTime = this.$moment(this.date);
      let timeDuration = this.$moment
        .duration(useTime.diff(currentTime))
        .asMilliseconds();
      let hour = ("0" + Math.floor(timeDuration / (60 * 60 * 1000))).slice(-2); //시간

      let minute = (
        "0" + Math.floor((timeDuration - hour * 60 * 60 * 1000) / (1000 * 60))
      ).slice(-2); // 분
      let second = (
        "0" +
        Math.floor(
          (timeDuration - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000
        )
      ).slice(-2); // 초
      return (this.duration = hour + ":" + minute + ":" + second);
    },

    startTimer() {
      this.stopTimer();
      this.timerId = setInterval(this.timer, 1000);
      // if (this.duration === "00:00:00") {
      //   this.stopTimer();
      // }
    },

    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    },
    tick() {
      this.timeDuration = this.timeDuration - 1000;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
}
img {
  margin-right: unit(5);
}
</style>
