<template>
  <!--festival o-->
  <div v-if="id === 'festival'" class="ticket-action__form">
    <div v-if="ticket?.service" class="service_checkout">
      <div class="ticket-action__form-header">{{ $__t('물품을 찾으시겠습니까?') }}</div>
      <div class="ticket-action__form-body">
        <div class="check-out-notice">
          {{ $__t("'예' 버튼을 누를 경우 물품을 찾아간 것으로 간주하여 더 이상 티켓을 사용할 수 없습니다.") }}
          <span class="focus">{{ $__t('물품 수령 시 현장 직원의 안내 하에 버튼을 눌러주세요.') }}</span>
        </div>
      </div>
      <div class="ticket-action__form-footer">
        <div class="footer__button-area">
          <button v-button type="button" @click.prevent="confirm" class="button button--submit" :disabled="isProcessing">
            {{ $__t('Confirm') | toUpperCase }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="ticket-action__form-header">
        <div class="ticket-action__form-header--primary" v-html="formHeaderTextPrimary"></div>
      </div>
      <div class="ticket-action__form-body">
        <transition name="fade" mode="out-in">
          <div class="check-out-info">
            <div class="section">
              <h2 class="section__header">
                {{ $__t('보관정보') }}
              </h2>
              <ul class="list">
                <li class="item">
                  <div class="key">
                    {{ $__t('{estimated-price}.section.list.key.DATE_INFO') }}
                  </div>
                  <div class="value">
                    {{ dateInfo }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      <div class="ticket-action__form-footer">
        <div class="footer__button-area">
          <button v-button type="button" @click="$emit('close-slider')" class="button button--cancel">
            {{ $__t('Cancel') | toUpperCase }}
          </button>
          <button v-button type="button" @click.prevent="confirm" class="button button--submit" :disabled="isProcessing">
            {{ $__t('Confirm') | toUpperCase }}
          </button>
        </div>
      </div>
    </div>
    <modal id="checkout-alert-modal" ref="checkout-alert-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <checkout-alert id="checkout-alert" @close-modal="closeModal('checkout-alert-modal')" />
      </template>
    </modal>
  </div>
  <!--festival x-->
  <div v-else class="ticket-action__form">
    <div v-if="ticket?.service" class="service_checkout">
      <div class="ticket-action__form-header">
        <span class="strong">{{ checkout }}</span>현재 시간으로 체크아웃 하시겠습니까?
      </div>
      <div class="ticket-action__form-body">
        <transition name="fade" mode="out-in">
          <div class="check-out-notice">
            <ul>
              <li>
                • 체크아웃 하시기 전에 라커에 잊으신 물건은 없는지 확인 후 체크아웃을 진행해주세요.
                <span class="strong">체크아웃 후에는 라커 문을 열 수 없습니다.</span>
              </li>
              <li>• 체크아웃 이후 라커 문을 열어야 하는 경우 상점 사장님께 요청해주세요.</li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="ticket-action__form-footer">
        <div class="footer__button-area">
          <button v-button type="button" @click.prevent="confirm" class="button button--submit" :disabled="isProcessing">
            {{ $__t('Confirm') | toUpperCase }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="ticket-action__form-header">
        <div class="ticket-action__form-header--primary" v-html="formHeaderTextPrimary"></div>
      </div>
      <div class="ticket-action__form-body">
        <transition name="fade" mode="out-in">
          <div class="check-out-info">
            <div class="section">
              <h2 class="section__header">
                {{ $__t('보관정보') }}
              </h2>
              <ul class="list">
                <li class="item">
                  <div class="key">
                    {{ $__t('{estimated-price}.section.list.key.DATE_INFO') }}
                  </div>
                  <div class="value">
                    {{ dateInfo }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      <div class="ticket-action__form-footer">
        <div class="footer__button-area">
          <button v-button type="button" @click="$emit('close-slider')" class="button button--cancel">
            {{ $__t('Cancel') | toUpperCase }}
          </button>
          <button v-button type="button" @click.prevent="confirm" class="button button--submit" :disabled="isProcessing">
            {{ $__t('Confirm') | toUpperCase }}
          </button>
        </div>
      </div>
    </div>
    <modal id="checkout-alert-modal" ref="checkout-alert-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <checkout-alert id="checkout-alert" @close-modal="closeModal('checkout-alert-modal')" />
      </template>
    </modal>
  </div>
</template>

<script>
import CheckoutAlert from '@/components/pages/service-product/utils/CheckoutAlert.vue';

export default {
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    id: {
      type: String
    }
  },

  name: 'CheckOutForm',
  components: {
    CheckoutAlert
  },
  data() {
    return {
      isProcessing: false
    };
  },

  computed: {
    checkout() {
      const now = this.$moment();
      let text = '';
      let checkoutDate = now.format('MM.DD');
      let checkoutDay = this.$moment(now).day();
      switch (checkoutDay) {
        case 1:
          checkoutDay = '(월)';
          break;

        case 2:
          checkoutDay = '(화)';
          break;

        case 3:
          checkoutDay = '(수)';
          break;

        case 4:
          checkoutDay = '(목)';
          break;
        case 5:
          checkoutDay = '(금)';
          break;
        case 6:
          checkoutDay = '(토)';
          break;
        case 7:
          checkoutDay = '(일)';
          break;
      }
      let checkoutTime = now.format('HH:mm');
      return checkoutDate + ' ' + checkoutDay + ' ' + checkoutTime + ' ';
    },
    formHeaderTextPrimary() {
      return this.$__t('{ticket-action__form-header--primary}.check_out');
    },

    isLocker() {
      return this.ticket?.ticket_type === 'LOCKER' || this.order?.product_data?.store_type === 'LOCKER';
    },

    dateInfo() {
      const momentDateStart = this.$moment(this.ticket.datetime_check_in);
      const momentDateEnd = this.$moment();
      const momentNow = this.$moment();

      let text = '';

      if (momentDateStart.format('YYYY-MM-DD') === momentDateEnd.format('YYYY-MM-DD')) {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('HH:mm')}`;
        }
      } else {
        if (momentNow.format('YYYY-MM-DD') === momentDateStart.format('YYYY-MM-DD')) {
          text = `${this.$__t('{date-time-picker}.today')} ${momentDateStart.format('HH:mm')} - ${momentDateEnd.format('MM/DD')}(${this.$__t(
            'SSA_' + momentDateEnd.format('ddd').toUpperCase()
          )}) ${momentDateEnd.format('HH:mm')}`;
        } else {
          text = `${momentDateStart.format('MM/DD')}(${this.$__t('SSA_' + momentDateStart.format('ddd').toUpperCase())}) ${momentDateStart.format(
            'HH:mm'
          )} - ${momentDateEnd.format('MM/DD')}(${this.$__t('SSA_' + momentDateEnd.format('ddd').toUpperCase())}) ${momentDateEnd.format('HH:mm')}`;
        }
      }

      return text;
    }
  },

  methods: {
    async fecthCheckOut() {
      const payload = {
        type: this.ticket.ticket_type,
        code: this.ticket.ticket_code,
        data: {
          account: this.account,
          password: this.password
        }
      };

      await this.$store.dispatch(`serviceProduct/checkout`, {
        ...payload,
        options: { clearGroup: true }
      });
    },

    async confirm() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      this.$store.commit('loading/SET_TRUE');

      try {
        await this.fecthCheckOut();
        setTimeout(() => {
          this.$store.commit('loading/SET_FALSE');
          this.isProcessing = false;
          this.$emit('fetch-order');
          this.$emit('fetch-ticket');
          this.$emit('close-slider');
        }, 100);
      } catch (e) {
        // console.log('e', e);
        // // this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
        // if (e.status === 'error') {
        //   console.log('ria');
        //   this.openModal('checkout-alert-modal');
        // }
        this.openModal('checkout-alert-modal');
        this.$store.commit('loading/SET_FALSE');
        this.isProcessing = false;

        this.$emit('fetch-order');
        this.$emit('fetch-ticket');

        // this.$emit('close-slider');
      } finally {
        // setTimeout(() => {
        //   this.$store.commit('loading/SET_FALSE');
        //   this.isProcessing = false;
        //   this.$emit('fetch-order');
        //   this.$emit('fetch-ticket');
        //   this.$emit('close-slider');
        // }, 100);
      }
    },
    openRef(ref) {
      this.$refs[ref].open();
    },

    closeRef(ref) {
      // console.log('closeRef', ref, this, this.$refs[ref]);
      this.$refs[ref].close();
    },

    openModal(ref) {
      // console.log('ref', ref);
      this.$refs[ref].open();
    },

    closeModal(ref) {
      this.$refs[ref].close();
      this.$emit('close-slider');
    }
  }
};
</script>
<style lang="scss" scoped>
.ticket-action__form {
  .service_checkout {
    .ticket-action__form-header {
      padding-bottom: unit(15);
      font-size: unit(20);
      font-weight: 600;
      line-height: unit(30);
      .strong {
        color: #ff4646;
      }
    }
    .ticket-action__form-body {
      .check-out-notice {
        font-weight: 400;
        font-size: unit(14);
        line-height: unit(20);
        .focus {
          text-decoration: underline;
        }
        li {
          margin: unit(10);
          .strong {
            color: #ff4646;
          }
        }
      }
    }
    .ticket-action__form-footer {
      // background-color: none;
      background-color: $color-white;
      
      button {
        background-color: #48d9eb;
        border-radius: unit(24);
      }
    }
  }
}
</style>
