<template>
  <div class="container">
    <div class="message">{{ $__t('당일 구매 또는 지난 상품의 경우, 취소 및 환불이 불가합니다.') }}</div>
    <div class="buttons">
      <button class="confirm" @click="$emit('close-modal')">{{ $__t('확인') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: []
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .message {
    text-align: center;
    font-size: unit(20);
    margin-top: unit(30);
    margin-bottom: unit(58);
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: unit(10);
    button {
      font-size: unit(14);
      width: 100%;
      height: unit(46);
      border-radius: unit(24);
      text-align: center;
      margin: auto;

      &.confirm {
        background-color: #48d9eb;
        color: #ffffff;
        // border: 1px solid #e1e4e6;
      }
    }
  }
}
</style>
