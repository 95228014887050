<template>
  <div class="ticket-check-in">
    <h1 v-if="id === 'festival'" class="header">
      📸 {{ $__t("물품 확인 및 분실 방지를 위해 보관 물품 사진을 촬영하세요.") }}
    </h1>
    <h1 v-else class="header">{{ $__t("Checked in completed!") }}</h1>
    <p v-if="id !== 'festival'" class="explanatory">
      <!-- <strong>물품 사진</strong>을 꼭 <strong>촬영하셔야 보관이 완료됩니다.</strong> 사진 촬영을 하지 않아 보관 완료가 되지 않을 시에는
      <b>티켓이 만료</b>될 수 있습니다. -->
      📸 {{ $__t("물품 확인 및 분실 방지를 위해 보관 물품 사진을 촬영하세요.") }}
    </p>
    <div v-if="loading" class="loading">
      <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
    </div>
    <div class="image-frame">
      <p v-if="!imagePreview" class="placeholder">
        {{ $__t("물품 사진을 촬영해주세요.") }}
      </p>
      <!-- <input v-else type="file" accept="image/*" capture="environment" class="input" @change="onChangeFile" /> -->
      <img v-else :src="imagePreview" class="image" ref="image" />
    </div>
    <ul v-if="id === 'festival'" class="info-list">
      <li class="info-list__item">
        <span>{{ $__t("반드시 보관소에서 사진을 찍어주세요.") }}</span>
      </li>
      <li class="info-list__item">
        <span>{{ $__t("라벨이 보이도록 찍어주세요.") }}</span>
      </li>
      <li class="info-list__item">
        <span>{{ $__t("보관 중 파손 or 분실 시 증거 자료가 될 수 있습니다.") }}</span>
      </li>
    </ul>
    <ul v-else class="info-list">
      <li class="info-list__item">
        <span>{{ $__t("맡기실 물품이 잘 보이도록 찍어주세요.") }}</span>
      </li>
      <li class="info-list__item">
        <span>{{ $__t("보관 중 파손 or 분실 시 증거 자료가 될 수 있습니다.") }}</span>
      </li>
    </ul>
    <div class="actions">
      <template v-if="wni.isNative">
        <template v-if="resource">
          <button
            v-ripple
            type="button"
            @click="onClickTakePhoto"
            class="btn btn--cancel"
          >
            {{ $__t("재촬영") }}
          </button>
          <button
            v-ripple
            type="button"
            @click="onClickConfirm"
            class="btn btn--confirm"
            :class="{ 'btn--disabled': !imagePreview }"
          >
            {{ $__t("확인") }}
          </button>
        </template>
        <template v-else>
          <button v-ripple type="button" class="btn btn--cancel" @click="$emit('close')">
            {{ $__t("닫기") }}
          </button>
          <button
            v-ripple
            type="button"
            @click="onClickTakePhoto"
            class="btn btn--confirm"
          >
            {{ $__t("사진 촬영") }}
          </button>
        </template>
      </template>

      <template v-else>
        <template v-if="imagePreview">
          <div v-ripple class="btn btn--cancel">
            <input
              id="file"
              type="file"
              accept="image/*"
              capture="environment"
              class="input"
              @change="onChangeFile"
            />
            <label for="file">{{ $__t("재촬영") }}</label>
          </div>
          <button
            v-ripple
            type="button"
            @click="onClickConfirm"
            class="btn btn--confirm"
            :class="{ 'btn--disabled': !imagePreview }"
          >
            {{ $__t("확인") }}
          </button>
        </template>
        <template v-else>
          <button v-ripple type="button" class="btn btn--cancel" @click="$emit('close')">
            {{ $__t("취소") }}
          </button>
          <div v-ripple class="btn btn--confirm">
            <input
              id="file"
              type="file"
              accept="image/*"
              capture="environment"
              class="input"
              @change="onChangeFile"
            />
            <label for="file">{{ $__t("사진 촬영") }}</label>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import loadImage from "blueimp-load-image";
import Lottie from "vue-lottie";
import LottieLoading from "@/assets/lottie/loading-primary.json";

export default {
  props: ["ticket", "id"],

  data() {
    return {
      loading: false,
      lottieOptions: {
        animationData: LottieLoading,
      },

      file: null,
      resource: null,
      imagePreview: "",
    };
  },

  methods: {
    requestToAccessCamera() {
      return new Promise(async (resolve, reject) => {
        if (this._runningNativeForRequestingToAccessCamera) {
          reject(new Error("이미 네이티브 동작이 실행중입니다. (RequestToAccessCamera)"));
          return;
        }

        this._runningNativeForRequestingToAccessCamera = true;

        // @MEMO: Native 호출에 대한 타임아웃이 없음. 추후 고려 필요
        this.wni.execute("wnPermissionVideoAccess", {
          callback: this.wni.cb((result) => {
            try {
              const { status } = result;
              resolve(status.toLowerCase());
            } catch (e) {
              reject(e);
            } finally {
              this._runningNativeForRequestingToAccessCamera = false;
            }
          }),
        });
      });
    },

    uploadResourceByCamera() {
      return new Promise(async (resolve, reject) => {
        if (this._runningNativeForUploadingResourceByCamera) {
          reject(
            new Error("이미 네이티브 동작이 실행중입니다. (UploadResourceByCamera)")
          );
          return;
        }

        this._runningNativeForUploadingResourceByCamera = true;

        this.wni.execute("wnCameraUpload", {
          type: "camera",
          bucket: "lugstay",
          path: `${this.$store.state.config.mode}/luggage-app`,
          editing: false,
          callback: this.wni.cb(async ({ size, url, error }) => {
            try {
              if (error) {
                throw new Error(error);
              }

              resolve({ url });
            } catch (e) {
              reject(e);
            } finally {
              this._runningNativeForUploadingResourceByCamera = false;
            }
          }),
        });
      });
    },

    uploadResourceImageFromURL(url) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await this.axios.post(
            this.$store.state.config.apiURL + "/v2/resources/image",
            {
              key: this.$store.state.auth.resource_key,
              tag: "luggage_photo",
              resource_type: "image",
              resource_url: url,
            },
            { headers: this.$store.state.config.userHeaders }
          );

          const { data, error, status } = response?.data;

          if (error) {
            throw new Error(error);
          }

          resolve(data);
        } catch (e) {
          reject(e);
        } finally {
          //
        }
      });
    },

    async onClickTakePhoto() {
      if (this.loading) {
        // 중복 클릭 방지
        return;
      }

      this.loading = true;

      try {
        const status = await this.requestToAccessCamera();

        if (status !== "granted") {
          setTimeout(() => {
            alert("카메라 권한을 설정해주세요.");
            this.wni.execute("wnOpenAppSetting", { type: "general" });
          }, 500);
          throw new Error("카메라 권한이 없습니다.");
        }

        const { url } = await this.uploadResourceByCamera();
        const resource = await this.uploadResourceImageFromURL(url);

        this.imagePreview = url;
        this.resource = resource;

        this.loading = false;
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e.message, status: "error" });
      } finally {
        this.loading = false;
      }
    },

    onChangeFile(e) {
      this.loading = true;
      this.file = e.target.files[0];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        (e) => {
          this.$data.imagePreview = e.target.result;
          setTimeout(() => {
            const image = this.$refs.image;
            image.src = e.target.result;
            this.loading = false;
          }, 0);
        },
        false
      );

      reader.readAsDataURL(this.file);
    },

    uploadImageForWeb() {
      return new Promise((resolve, reject) => {
        try {
          const loadingImage = this.file;

          loadImage(
            loadingImage,
            (img, data) => {
              img.toBlob(async (blob) => {
                let imageFile = null;
                try {
                  imageFile = new File([blob], loadingImage.name, {
                    type: loadingImage.type,
                  });
                } catch (e) {
                  imageFile = Object.assign(blob, loadingImage.name, {
                    type: loadingImage.type,
                  });
                } finally {
                  let formData = new FormData();
                  formData.append("key", this.$store.state.auth.resource_key);
                  formData.append("tag", "luggage_photo");
                  formData.append("file", imageFile);

                  this.resource = await this.uploadResourceForWeb(formData);

                  resolve(this.resource);
                }
              });
            },
            {
              orientation: true,
              canvas: true,
              maxWidth: 600,
            }
          );
        } catch (e) {
          reject(e);
        }
      });
    },

    async uploadResourceForWeb(formData) {
      const response = await this.axios.post(
        this.$store.state.config.apiURL + "/v2/resources/upload",
        formData,
        {
          headers: this.$store.state.config.userHeaders,
        }
      );

      let resource = null;

      try {
        resource = response.data?.data?.resources[0];
      } catch (e) {
        resource = null;
      }

      return resource;
    },

    updateTicket() {
      return this.$store.dispatch("serviceProduct/updateTicket", {
        code: this.ticket.ticket_code,
        resources: { luggage_photo: this.resource },
      });
    },

    checkIn() {
      return this.$store.dispatch("serviceProduct/checkin", {
        code: this.ticket.ticket_code,
      });
    },

    //@MEMO: 9/15 UMF컨텐츠를 위해서 확인 버튼 클릭 시, 이미지 업로드 && 체크인도 같이 되도록 수정
    async onClickConfirm() {
      this.loading = true;

      try {
        if (!this.wni.isNative) {
          await this.uploadImageForWeb();
        }

        await this.updateTicket();

        await this.checkIn();

        this.$emit("fetch-order");

        this.loading = false;
        this.$emit("close");
      } catch (e) {
        this.$store.commit("alert/ADD_ITEM", { message: e, status: "error" });
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    Lottie,
  },
};
</script>

<style scoped lang="scss">
.ticket-check-in {
  background: $color-white;

  .header {
    margin-top: unit(24);
    font-size: unit(22);
    line-height: 1.45;
    font-weight: bold;
  }

  .explanatory {
    margin-top: unit(4);
    font-size: unit(16);
    line-height: 1.43;
    letter-spacing: -0.006em;
    color: #292a2b;

    strong,
    b {
      font-size: unit(16);
      font-weight: bold;
    }

    b {
      color: $color-red;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image-frame {
    margin-top: unit(30);
    padding-bottom: 60%;
    border: 1px solid #e1e4e6;
    border-radius: unit(10);

    .placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: unit(16);
      font-weight: unit(14);
      line-height: 1.43;
      color: #a9afb3;
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-list {
    margin-top: unit(20);

    &__item {
      padding-left: unit(16);
      font-size: unit(14);
      line-height: 1.71;
      letter-spacing: -0.006em;
      color: #61676c;

      &::before {
        content: "·";
        position: absolute;
        top: unit(2);
        left: 0;
        margin-right: unit(2);
        font-size: unit(14);
        line-height: 1.71;
        letter-spacing: -0.006em;
        color: #61676c;
      }
    }
  }

  .actions {
    margin-top: unit(50);
    display: flex;
    gap: unit(10);

    .btn {
      flex: 1;
      font-size: unit(14);
      line-height: 1.43;
      padding: unit(15) unit(16);
      text-align: center;

      &--cancel {
        color: #a9afb3;
        background: $color-white;
        border: solid 1px #e1e4e6;
        border-radius: unit(10);
      }

      &--confirm {
        color: $color-white;
        background: #48d9eb;
        border-radius: unit(10);
      }

      &--disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .input {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }
  }
}
</style>
